
// 基础配置相关
const state = {
    appid: window.sessionStorage.getItem("appid"),
    user: JSON.parse(window.sessionStorage.getItem("user")|| '{}'),
    menu:JSON.parse(window.sessionStorage.getItem('navList') || '[]'),
    area:JSON.parse(window.sessionStorage.getItem('area') || '[]'),
    aMenu:window.sessionStorage.getItem('aMenu') || '1',
    
  };
  

  const mutations = {
    SET_Appid: (state, info) => {
      window.sessionStorage.setItem("appid", info);    
      state.appid= info
    },
    
    SET_User: (state, info) => {
        window.sessionStorage.setItem("user", JSON.stringify(info));
        state.user= info
    },
    SET_Menu: (state, info) => {
        window.sessionStorage.setItem("navList", JSON.stringify(info));
        state.menu= info
    },
    SET_Area: (state, info) => {
      
      window.sessionStorage.setItem("area", JSON.stringify(info));
      state.area= info
    },
    SET_aMenu: (state, info) => {
     
      window.sessionStorage.setItem("aMenu", info);
      state.aMenu= info
    },
  };
  
  const actions = {
    setAppid({ commit }, info) {
      
      commit("SET_Appid", info);
    },
    setUser({ commit }, info) {
        
        commit("SET_User", info);
    },
    setMenu({ commit }, info) {
        
        commit("SET_Menu", info);
    },
    setArea({ commit }, info) {
        
        commit("SET_Area", info);
    },
    set_aMenu({ commit }, info) {
          
        commit("SET_aMenu", info);
    },
   
  };
  const getters = {
    invoiceCode: (state) => state.appid,
  
  };
  // store.dispatch("m_login/setAppid", 'appid') 

// store.state.m_login.appid
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
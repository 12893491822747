<template>
  <div class="flex">
    <el-image
      class="loginImg"
      :src="require('@/assets/image/login.png')"
      fit="cover"
    />

    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      :size="formSize"
    >
      <h2>欢迎使用兵魂设备</h2>
      <el-form-item label="登录账号" prop="user_name">
        <el-input  v-model="ruleForm.user_name" placeholder="请输入登录账号" />
      </el-form-item>
      <el-form-item label="账号密码" prop="password">
        <el-input type="password"  placeholder="请输入密码" show-password v-model="ruleForm.password" />
      </el-form-item>
      <el-form-item label="验证码"  prop="verify_code" class="codeCont">
        <el-input v-model="ruleForm.verify_code" placeholder="请输入验证码" />
        <img @click="changeCode" :src="imgUrl" alt="" />
        <div class="tips">
           <span  @click="changeCode">看不清楚换一张</span>
        </div>
      </el-form-item>
      <div>
        <br />
        <el-button
          size="medium "
          type="primary"
          round
          @click="submitForm()"
          >登录{{ store.state.m_login.token }}</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { reactive, ref, computed, nextTick,onMounted } from "vue";
import { ElMessage, ElMessageBox} from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { getAppid, getCaptcha, getLogin, getMenu } from "@/api/urer";
import { MD5Fn } from "@/assets/js/common.js";
import { initRoutes } from "@/router/index.js";

let imgUrl = ref();
const router = useRouter();
const store = useStore();
const formSize = ref("default");
const ruleFormRef = ref();
const menu = reactive([]);
const ruleForm = reactive({
  user_name: "",
  password: "",
  verify_code: "",
});
nextTick(() => {
  localStorage.setItem("token", "");
  store.dispatch("m_login/set_aMenu", '1') 
});
const changeCode = () => {
  getCaptcha({ url: "/api/backend/login/captcha" }).then((res) => {
    if (res && res.data) {
      imgUrl.value = res.data.image;
    }
  });
};
if (localStorage.getItem("appid")) {
  getCaptcha({ url: "/api/backend/login/captcha" })
    .then((res) => {
      if (res && res.data) {
        imgUrl.value = res.data.image;
      }
      return res;
    })
    .then((res) => {
      if (res && res.code == 1) {
      } else {
        getAppid({ url: "/api/backend/create_app/index" })
          .then((res) => {
            if (res && res.data && res.data.appid) {
              localStorage.setItem("appid", res.data.appid);
            } else {
              localStorage.setItem("appid", "");
            }
          })
          .then((res) => {
            getCaptcha({ url: "/api/backend/login/captcha" }).then((res) => {
              if (res && res.data) {
                imgUrl.value = res.data.image;
              }
            });
          });
      }
    });
} else {
  getAppid({ url: "/api/backend/create_app/index" })
    .then((res) => {
      if (res && res.data && res.data.appid) {
        localStorage.setItem("appid", res.data.appid);
      } else {
        localStorage.setItem("appid", "");
      }
    })
    .then((res) => {
      getCaptcha({ url: "/api/backend/login/captcha" }).then((res) => {
        if (res && res.data) {
          imgUrl.value = res.data.image;
        }
      });
    });
}

const rules = reactive({
  user_name: [{ required: true, message: "请填写账号名", trigger: ['blur','change'] }],
  password: [{ required: true, message: "请填写正确的密码", trigger: ['blur','change']}],
  verify_code: [
    { required: true, message: "请填写正确的验证码", trigger: ['blur','change'] },
    { min: 4, max: 4, message: "验证码的为4为数", trigger: ['blur','change']},
  ],
});
//登录验证的form 表单
const submitForm = async () => {
  // if (!formEl) return;
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      //登录接口
      getLogin({ url: "/api/backend/login/login", data: MD5Fn(ruleForm) })
        .then((res) => {
          if (res.code == 1) {
            if (res && res.data && res.data.token) {
              localStorage.setItem("token", res.data.token);
              store.dispatch("m_login/setUser", res.data);
            } else {
              localStorage.setItem("token", "");
            }
          } else {
            ElMessage({ message: res.msg, type: "error" });
            changeCode()
          }
          return res;
        })
        .then((res) => {
          if (res && res.code == 1) {
            //登录完成  获取菜单栏
            getMenu({ url: "/api/backend/menu/index" }).then((res) => {
              if (res && res.code == 1) {
                store.dispatch("m_login/setMenu", res.data);
                initRoutes();
                router.push({ name: "main" });
              }
            });
          }
        });
    } else {
      console.log("error submit!", fields);
    }
  });
};
onMounted(()=>{
  window.addEventListener('keydown',(event)=>{
       if(event.keyCode==13){
         submitForm()
       }
  })
})
</script>
<style scoped lang="scss">
.demo-ruleForm {
  width: 430px;
  margin: 221px 0 0 114px;

  .el-form-item {
    flex-direction: column;
  }
  :deep .el-form-item__label {
    text-align: left;
    justify-content: flex-start;
  }
  :deep .el-input__wrapper {
    height: 50px;
    border-radius: 15px;
  }
  .el-button {
    width: 100%;
  }
}
.codeCont .el-form-item__content {
  .el-input {
    width: calc(100% - 130px);
  }
}
.tips {
  width: 100%;
  color: #999;
  text-align: right;
}
.loginImg {
  height: 100vh;
  width: 1014px;
}
:deep.el-button{
  height: 50px;
  border-radius: 30px !important;
  background: linear-gradient(270deg, #2761E7 0%, #2761E7 0%, #238AF7 100%, rgba(39,97,231,0.00) 100%, rgba(39,97,231,0.00) 100%);
}
</style>

<template>
  <router-view/>
</template>
<script setup>

</script>

<style lang="scss">

  @import './assets/iconfont/iconfont.css';
</style>

//
import CryptoJS from "crypto-js";
import MD5 from "js-md5/src/md5";

export function MD5Fn(obj) {
    
    if(obj.time == undefined){
        obj.time =  String(Math.ceil(Date.now()/1000));
    }
    let keyArr = [];
    let newObj = {};
    for(var i in obj){
        if(obj[i] == null || obj[i] == undefined){
            obj[i]='';
        }
        keyArr.push(i);
    }
    keyArr = keyArr.sort();
    for(let i=0;i<keyArr.length;i++){
        newObj[keyArr[i]]=obj[keyArr[i]]
    }
    
    let str = '';
    for(var i in newObj){
        str += i+'='+newObj[i]+'&'
    }
    str = str.substr(0,str.length-1)
    str = encodeURIComponent(str);
    
    let srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(obj));
    var key = CryptoJS.enc.Utf8.parse("backend000000000");
	var iv = CryptoJS.enc.Utf8.parse('backend000000000');
    
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,   //加密模式CBC ECB CFB OFB CTRGladman CTR
        padding: CryptoJS.pad.Pkcs7  //填充方式 128
    })
    
    let end = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
   
    return {data:end,sign:MD5(str)}
}
/**
 * @desc 函数防抖
 * @param fn 函数
 * @param timerDelay 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function debounce(fn, timerDelay, immediate = false) {
	let timer;
	return function() {
		let that = this;
		let args = arguments;

		if (timer) clearTimeout(timer);
		if (immediate) {
			var callNow = !timer;
			timer = setTimeout(() => {
				timer = null;
			}, timerDelay)
			if (callNow) fn.apply(that, args)
		} else {
			timer = setTimeout(function() {
				fn.apply(that, args)
			}, timerDelay);
		}
	}
}
// 封装请求参数
import http from "./index";


export function getAppid({ method = "get", url, data = {}, params = {} }) {
    return http({
        method,
        url,
        data,
        params,
    })
}
export function getCaptcha({ method = "get", url, data = {}, params = {} }) {
    return http({
        method,
        url,
        data,
        params,
    })
}
export function getLogin({ method = "post", url, data = {}, params = {} }) {
    return http({
        method,
        url,
        data,
        params,
    })
}
export function getloginOut({ method = "post", url, data = '', params = {} }) {
    return http({
        method,
        url,
        data,
        params,
    })
}
export function getMenu({ method = "post", url, data = '', params = {} }) {
    return http({
        method,
        url,
        data,
        params,
    })
}
import axios from "axios";
import {ElMessage} from "element-plus";
import router from '../router'
// 请求

// const baseURL = 'htt 8.1.119:8888/';
// const baseURL='https://beijingapi.dzkj.online/';
const baseURL=window.config.baseUrl;
// const baseURL = 'http://192.168.1.30:8888/';
// const baseURL = 'http://192.168.1.47/index.php/';
const http =  axios.create({
    baseURL:baseURL,
    timeout:6000
});
window.sessionStorage.setItem("baseURL", baseURL);
// 请求拦截
http.interceptors.request.use(config => {
    //请求头设置
    let appid = localStorage.getItem('appid') || ''
    let token = localStorage.getItem('token') || ''
    // let token = store.state.m_login.appid || ''
    
    if(appid) {
        config.headers.Authorization = `${appid}`+','+sessionStorage.getItem('aMenuName')
    }
    if(config.url != "/api/backend/create_app/index"){
        if(token) {
            config.headers.token = `${token}`
        }
    }
    
    // config.headers.token = appid
    
    return config
}, err => {
    console.log(err);
})
// 响应拦截
http.interceptors.response.use(arr => {
    // 对响应码的处理
    if(arr.status == 200){
        
        return arr.data
    }else{
        return arr.data
    }
    
}, str => {
    
    let arr = str.response.data;
    switch (arr.code){
        case 400:
            ElMessage({
                message: arr.msg,
                type: 'warning',
            })
            break;
        case 401:
            localStorage.setItem('token','');
            router.push({ name: "login" });
            ElMessage({
                message: arr.msg,
                type: 'warning',
            })
            break;
        case 403:
            localStorage.setItem('token','')
            router.push({ name: "login" });
            ElMessage({
                message: arr.msg,
                type: 'warning',
            })
            break;
        case 404:
            ElMessage({
                message: arr.msg,
                type: 'warning',
            })
            break;
        case 422:
            ElMessage({
                message: arr.msg,
                type: 'warning',
            })
            break;
        case 500:
            ElMessage({
                message: arr.msg,
                type: 'error',
            })
            break;
    }
})
// 返出
export default http

import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router'
import { useStore } from "vuex";
const store = useStore();
import Login from '@/components/Login';
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  

  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/Main'),
    children:[
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue')
      },
      {
        path: '/AccountViewList',
        name: 'AccountViewList',
        component: () => import('@/views/goods/AccountViewList.vue')
      },
      {
        path: '/activity_indexC',
        name: 'activity_indexC',
        component: () => import('@/views/activity/IndexViewC.vue')
      },
      {
        path: '/OrderInfo',
        name: 'OrderInfo',
        component: () => import('@/views/game/OrderInfo')
      },
      {
        path: '/storeList',
        name: 'storeList',
        component: () => import('@/views/store/Storelist')
      },
      {
        path: '/DeviceDetail',
        name: 'DeviceDetail',
        component: () => import('@/views/store/DeviceDetail')
      },
	
      {
        path: '/target_one',//起倒靶-单个
        name: 'target_one',
        component: () => import('@/views/store/target_one')
      },
	  
      {
        path: '/target_more',//2起倒靶-多个
        name: 'target_more',
        component: () => import('@/views/store/target_more')
      },
      {
        path: '/Snail-Fight',//3对抗设备
        name: 'Snail-Fight',
        component: () => import('@/views/store/Snail-Fight')
      },
      {
        path: '/Shooting_practice',//1胸环靶-多个
        name: 'Shooting_practice',
        component: () => import('@/views/activity/Shooting_practice')
      }
    ]
  }
]

// 动态导入路由
export function initRoutes() {
  if (!localStorage.getItem('token')) {
      
      router.push({ name: "login" });
  } else {
      const navList = JSON.parse(window.sessionStorage.getItem('navList')) ; // 获取state中缓存的根据权限获取的路由
      if (navList.length > 0) {
          
          navList.forEach(item => { // 循环获取的路由
              if (item.list.length != "0") {
                  const list = item.list
                  // const redirect = list[0].path;
                  // currentIndex.redirect = redirect //第一个路由重定向
                  
                  
                  list.forEach((t, i) => {
                   
                    router.addRoute('main',
                    {
                      name: t.name,
                      path: '/'+t.name,//+t.path
                      component: () => import('@/views/'+t.path)
                    }
                    ); // 执行动态添加路由方法，完成动态路由添加
                     // 将匹配的路由，添加到当前路由的对应子路由位置
                    
                  })
                  
              } else {
                
                if(item.name == 'dashboard'){
                 
                  
                  
                }
                
              }

          });
          router.addRoute('main',
              {
                name: "home",
                path: '/home',
                component: () => import('@/views/home/index.vue')}
            ); // 执行动态添加路由方法，完成动态路由添加
          // currentRoutes.push(currentIndex);
          // console.log('router.options.routes',router.options.routes)
      }
  }
}

// import {getUser} from '@/api/urer';
// getUser({url:'/login'});

var isF = false  //这个是用于判断动态路由是否已经被获取
const router = createRouter({
  history: createWebHashHistory(),
  mode:'history', // 加入这一句即可
  routes
})
//定义变量判断是否已经动态添加过，如果刷新后load永远为 0
let load = 0
router.beforeEach((to, from,next) => {
   //非登录、有菜单数据、 没有进行添加（或者刷新了）
  if (to.path !='/'&& !localStorage.getItem('token')) {
      router.push({ name: "login" });
  } 
  if (load === 0 && to.name != "login") {
    load++
   
    const navList = JSON.parse(window.sessionStorage.getItem('navList')) ; // 获取state中缓存的根据权限获取的路由
    navList.forEach((item,index) => { // 循环获取的路由
      if (item.list.length != "0") {
          const list = item.list
          // const redirect = list[0].path;
          // currentIndex.redirect = redirect //第一个路由重定向
          
          list.forEach((t, i) => {
            console.log(t.title,t.path,t.name)
            router.addRoute('main',
            {
              name: t.name,
              path: '/'+t.name,
              component: () => import('@/views/'+t.path)
            }
            ); // 执行动态添加路由方法，完成动态路由添加
             // 将匹配的路由，添加到当前路由的对应子路由位置
            
             let aMenu = window.sessionStorage.getItem('aMenu');
             let menuArr = [];
             if(aMenu!=1){
               menuArr= aMenu.split('-');
             }
            
             if(Number(index)+1 == menuArr[0] && Number(i)+1 == menuArr[1]){
               
                router.push({ name: t.name });
             }
             
          })
          
      } else {
        
        if(item.name == 'dashboard'){
         
         
          
        }
        
      }

    });
	
	
	
	
    router.addRoute('main',
      {
        name: "home",
        path: '/home',
        component: () => import('@/views/home/index.vue')},
		
		{
		  path: '/mywallet',//提现钱包管理
		  name: 'mywallet',
		  
		  component: () => import('@/views/withdraw/MywalletView')
		},
		{ 
		  path: '/Withdrawal',//提现管理
		  name: 'Withdrawal',
		  component: () => import('@/views/withdraw/WithdrawalView')
		},
    ); // 执行动态添加路由方法，完成动态路由添加
   
    next({ ...to, replace: true })
    
  }else{
    load++
    next()
  }
  
})
export default router
